import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Container, Row, Col, Button, Form, OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap';
import { FiPaperclip, FiX } from 'react-icons/fi'; // Clipper icon
import { toast } from 'react-toastify';
import { MdClose } from 'react-icons/md'; 
import AgentDropdown from './AgentDropdown';
import CodeEditor from './CodeEditor';
import useTypingEffect from './useTypingEffect';
import { ThemeContext } from '../App';
import { Analytics } from "@vercel/analytics/react"
import { AnsiUp } from 'ansi_up';


const AgentArena = () => {
  const [leftAgent, setLeftAgent] = useState(null);
  const [rightAgent, setRightAgent] = useState(null);
  const { theme } = useContext(ThemeContext);
  const ansiUp = new AnsiUp();
  const [goal, setGoal] = useState('');
  const [agents, setAgents] = useState([]);
  const [leftExecutedCode, setLeftExecutedCode] = useState('');
  const [rightExecutedCode, setRightExecutedCode] = useState('');
  const [leftOutput, setLeftOutput] = useState('');
  const [rightOutput, setRightOutput] = useState('');
  const [leftCompleted, setLeftCompleted] = useState(false);
  const [rightCompleted, setRightCompleted] = useState(false);
  const [ratingEnabled, setRatingEnabled] = useState(false);
  const [hasVoted, setHasVoted] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [votedResult, setVotedResult] = useState('');
  const [shareURL, setShareURL] = useState('');
  const [promptId, setPromptId] = useState(null);
  const [leftFile, setLeftFile] = useState(null);
  const [rightFile, setRightFile] = useState(null);
  const [isExample3, setIsExample3] = useState(false);
  const [userApiKeys, setUserApiKeys] = useState({});
  const [isRunningBoth, setIsRunningBoth] = useState(false);
  const [leftCodeCollapsed, setLeftCodeCollapsed] = useState(false);
  const [rightCodeCollapsed, setRightCodeCollapsed] = useState(false);
  const [runBothTriggered, setRunBothTriggered] = useState(false);
  const [file, setFile] = useState(null); // To store the uploaded file
  const [fileUploadAllowed, setFileUploadAllowed] = useState(false);


  const examplePrompts = [
    "what was AAPL stock yesterday",
    "Summarize for me a fascinating article about cats",
    "Find cheap hotels in Austin, Texas",
    "What is this paper about: 1706.03762"
  ];

  const displayedPrompt = useTypingEffect(examplePrompts);

  const removeFile = () => {
    setFile(null); // Reset file
  };


  useEffect(() => {
    axios.get('https://agent-arena.vercel.app/api/agents')
      .then(response => {
        setAgents(response.data);
      })
      .catch(error => console.error('Error fetching agents:', error));

    const token = localStorage.getItem('token');
    if (token) {
      axios.get('https://agent-arena.vercel.app/api/profile', {
        headers: { Authorization: `Bearer ${token}` }
      }).then(response => {
        setIsLoggedIn(true);
        setUserApiKeys(response.data.apiKeys || {});
      }).catch(error => {
        console.error('Error fetching profile:', error);
      });
    }
  }, []);

  useEffect(() => {
    if (leftCompleted && rightCompleted) {
      setRatingEnabled(true);
    }
  }, [leftCompleted, rightCompleted]);

  const handleLeftSelect = (agent) => {
    setLeftAgent(agent);
    setLeftExecutedCode(goal ? agent.code.replace('Enter Goal/Prompt Here', goal) : agent.code);
    resetVotingState();
    setLeftCodeCollapsed(false); // Ensure code editor is expanded when selecting a new agent
    setRightCodeCollapsed(false); // Ensure code editor is expanded when selecting a new agent


  };

  useEffect(() => {
    console.log('Left file updated:', leftFile);
  }, [leftFile]);

  useEffect(() => {
    console.log('Right file updated:', rightFile);
  }, [rightFile]);

  const checkBothCompleted = () => {
    if (leftCompleted && rightCompleted) {
      setIsRunningBoth(false);
      setRatingEnabled(true);
      setRunBothTriggered(false);
    }

  };

  const buttonStyles = {
    search: {
      normal: '#c5cae9',
      hover: '#9fa8da',
    },
    finance: {
      normal: '#a2d5f2',
      hover: '#8bc3de',
    },
    data: {
      normal: '#dcedc1',
      hover: '#c6d7a8',
    },
    research: {
      normal: '#ffe0b2',  // Research Example button color
      hover: '#ffcc80',    // Slightly darker shade for hover effect
    },
    automation: { normal: '#ffccbc', hover: '#ffab91' }, 

  };

  

  const handleRightSelect = (agent) => {
    setRightAgent(agent);
    setRightExecutedCode(goal ? agent.code.replace('Enter Goal/Prompt Here', goal) : agent.code);
    resetVotingState();
    setRightCodeCollapsed(false); // Ensure code editor is expanded when selecting a new agent
    setLeftCodeCollapsed(false); // Ensure code editor is expanded when selecting a new agent


  };

  const resetVotingState = () => {
    setLeftCompleted(false);
    setRightCompleted(false);
    setRatingEnabled(false);
    setHasVoted(false);
    setLeftOutput('');
    setRightOutput('');
    setVotedResult('');
    setShareURL('');
    setPromptId(null);
    setLeftFile(null);
    setRightFile(null);
    setIsExample3(false);
    setIsRunningBoth(false);
    setRunBothTriggered(false);
  };

  const handleRating = (rating) => {
    toast.success('Your rating has been submitted!');
    setHasVoted(true);
    setVotedResult(rating);

    setRatingEnabled(false);

    axios.post('https://agent-arena.vercel.app/api/ratings', {
      leftAgent: leftAgent._id,
      rightAgent: rightAgent._id,
      rating,
      executedCode: leftExecutedCode + '\n' + rightExecutedCode,
      leftOutput,
      rightOutput,
      savePrompt: isLoggedIn
    }).then(response => {
      setPromptId(response.data.promptId);
    }).catch(error => {
      console.error('Error saving rating:', error);
    });
  };

  const handleSavePrompt = async () => {
    if (!isLoggedIn) {
      toast.error('You need to be logged in to save prompts');
      return;
    }

    try {
      const response = await axios.post('https://agent-arena.vercel.app/api/prompts/save', {
        text: goal,
        leftAgent: leftAgent._id,
        rightAgent: rightAgent._id,
        leftExecutedCode,
        rightExecutedCode,
        votedResult,
        leftOutput,
        rightOutput
      }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      toast.success('Prompt saved successfully');
      setPromptId(response.data.promptId);
      setShareURL(`https://www.agent-arena.com/prompts/${response.data.promptId}`);
    } catch (error) {
      toast.error('Error saving prompt');
    }
  };

  const handleSearch = async (searchGoal) => {
    if (!agents.length) {
      toast.error('No agents available for selection');
      return;
    }
  
    try {
      const agentNames = agents.map(agent => agent.name);
      const response = await axios.post('https://agent-arena.vercel.app/api/goals/interpret-goal', { goal:searchGoal, agentNames });
      const { agent1, agent2 } = response.data;
      setFileUploadAllowed(agent1.allowsFileUpload || agent2.allowsFileUpload);

      setLeftAgent(agent1);
      setRightAgent(agent2);
      setLeftExecutedCode(searchGoal ? agent1.code.replace('Enter Goal/Prompt Here', goal) : agent1.code);
      setRightExecutedCode(searchGoal ? agent2.code.replace('Enter Goal/Prompt Here', goal) : agent2.code);

      if (agent1.allowsFileUpload && file) {
        setLeftFile(file); // Set the leftFile to the global file if leftAgent allows file uploads


      }
      if (agent2.allowsFileUpload && file) {
        setRightFile(file);
      }
      resetVotingState();
    } catch (error) {
      console.error('Error during agent search:', error);
  
      // Automatically select fallback agents if no agents are found
      const fallbackAgent1 = agents.find(agent => agent.name === 'openai general assistant (gpt-4o-2024-08-06)');
      const fallbackAgent2 = agents.find(agent => agent.name === 'llamaindex brave-search agent (gpt-4o-2024-08-06)');
  
      if (fallbackAgent1 && fallbackAgent2) {
        setLeftAgent(fallbackAgent1);
        setRightAgent(fallbackAgent2);
        setLeftExecutedCode(goal ? fallbackAgent1.code.replace('Enter Goal/Prompt Here', goal) : fallbackAgent1.code);
        setRightExecutedCode(goal ? fallbackAgent2.code.replace('Enter Goal/Prompt Here', goal) : fallbackAgent2.code);
        resetVotingState();
        // toast.info('Fallback agents selected: openai general assistant and llamaindex brave-search agent.');
      } else {
        toast.error('There was an issue selecting fallback agents. Please try again.');
      }
    }
  };
  

  const handleRunBoth = async () => {
    setLeftCodeCollapsed(true);
    setRightCodeCollapsed(true);
    if (!leftAgent || !rightAgent) {
      toast.error('Please select both agents before running');
      return;
    }

    setIsRunningBoth(true);
    setLeftCompleted(false);
    setRightCompleted(false);
    setRatingEnabled(false);
    setHasVoted(false);
    setLeftOutput('');
    setRightOutput('');
    setRunBothTriggered(prev => !prev);

    const leftFormData = new FormData();
    const rightFormData = new FormData();

    if (fileUploadAllowed && file) {
      leftFormData.append('general_file', file);
      rightFormData.append('general_file', file);
    }

    leftFormData.append('code', leftExecutedCode);
    leftFormData.append('agentId', leftAgent._id);
    if (leftFile) {
      leftFormData.append('general_file', leftFile);
    }
    if(file) {
      leftFormData.append('general_file', file);
      rightFormData.append('general_file', file);
    }
    Object.keys(userApiKeys).forEach((key) => {
      leftFormData.append(key, userApiKeys[key]);
    });



    rightFormData.append('code', rightExecutedCode);
    rightFormData.append('agentId', rightAgent._id);
    if (rightFile) {
      rightFormData.append('general_file', rightFile);
    }
    Object.keys(userApiKeys).forEach((key) => {
      rightFormData.append(key, userApiKeys[key]);
    });

    try {
      const [leftResponse, rightResponse] = await Promise.all([
        axios.post('https://agent-arena-wuwl.onrender.com/api/jobs/create', leftFormData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        }),
        axios.post('https://agent-arena-wuwl.onrender.com/api/jobs/create', rightFormData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        })
      ]);

      streamJobOutput(leftResponse.data.jobId, setLeftOutput, () => {
        setLeftCompleted(true);
        checkBothCompleted();
      });
      streamJobOutput(rightResponse.data.jobId, setRightOutput, () => {
        setRightCompleted(true);
        checkBothCompleted();
      });
    } catch (error) {
      toast.error('An error occurred while running the agents');
      setIsRunningBoth(false);
    }
  };

  const streamJobOutput = (jobId, setOutput, onComplete) => {
    const eventSource = new EventSource(`https://agent-arena-wuwl.onrender.com/api/jobs/${jobId}/stream`);
    let fullOutput = '';

    eventSource.onmessage = (event) => {
      let processedOutput = event.data;
      const coloredHtml = ansiUp.ansi_to_html(processedOutput);
      fullOutput += coloredHtml + "\n";
      setOutput((prevOutput) => prevOutput + coloredHtml + "\n");
    };

    eventSource.onerror = (error) => {
      console.error('EventSource failed:', error);
      eventSource.close();
      onComplete();
    };

    eventSource.addEventListener('end', () => {
      eventSource.close();
      onComplete();
    });
  };

  const handleShareSession = async () => {
    if (promptId) {
      navigator.clipboard.writeText(shareURL);
      toast.success('Session link copied to clipboard');
    } else {
      try {
        const response = await axios.post('https://agent-arena.vercel.app/api/prompts/saveWithoutUser', {
          text: goal,
          leftAgent: leftAgent._id,
          rightAgent: rightAgent._id,
          leftExecutedCode,
          rightExecutedCode,
          votedResult,
          leftOutput,
          rightOutput
        });
        const newPromptId = response.data.promptId;
        setPromptId(newPromptId);
        const newShareURL = `https://www.agent-arena.com/prompts/${newPromptId}`;
        setShareURL(newShareURL);
        navigator.clipboard.writeText(newShareURL);
        toast.success('Session link copied to clipboard');
      } catch (error) {
        toast.error('Error sharing session');
      }
    }
  };

  const setExample = async (exampleNumber) => {
    resetVotingState(); 
    setIsRunningBoth(false);     
    setLeftCompleted(false);     
    setRightCompleted(false);    
    setRunBothTriggered(false);  

    let newGoal = '';

    if (exampleNumber === 'surprise') {
      try {
        const response = await axios.post('https://api.openai.com/v1/chat/completions', {
          model: 'gpt-4o-2024-08-06',
          messages: [
            {
              role: 'system',
              content: `You are an assistant that generates random, engaging goals for users to try in an LLM agent comparison platform. 
                        These goals should be interesting, varied, and cover diverse areas such as:
                        - **Finance:** e.g., "Analyze the impact of interest rate changes on mortgage approvals."
                        - **Technology:** e.g., "Develop a chatbot that can assist with customer service inquiries."
                        - **Health:** e.g., "Create a meal plan for individuals with diabetes."
                        - **Education:** e.g., "Design an interactive lesson on the basics of quantum physics."
                        - **Creative Tasks:** e.g., "Write a short story set in a dystopian future."
                        - **Data Analysis:** e.g., "Interpret the trends in global smartphone sales over the past decade."
                        - **Everyday Life:** e.g., "Organize a weekly schedule that maximizes productivity for remote workers."
                        
                        Ensure that each goal is specific, clear, and suitable for comparing two different LLM agents. Avoid repeating similar types of goals, especially those related to travel and food. Strive for a balanced distribution across the mentioned categories.`
            },
            {
              role: 'user',
              content: `Please generate a random goal for the user to use. The goal should be:
                        - **Clear and Specific:** Precisely defined to allow effective comparison between two LLM agents.
                        - **Exciting and Diverse:** Cover a range of topics such as finance, technology, health, education, creative tasks, data analysis, and everyday life.
                        - **Educational or Practical:** Aim to provide value through learning or real-world application.
                        - **One-Liner:** Concise and to the point.
                        
                        **Important:** Avoid goals related to travel and food. Ensure that the generated goal does not fall into these categories and maintains diversity from previous goals. Do not include quotation marks around the goal when returning it.`
            }            
          ],
          max_tokens: 100
        }, {
          headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          },
        });

        newGoal = response.data.choices[0].message.content.trim();
      } catch (error) {
        toast.error('Failed to generate a surprise goal. Please try again.');
        return;
      }
    } else {
      let isExample3Update = false;

      switch (exampleNumber) {
        case 1:
          newGoal = 'What is new in California today';
          break;
        case 2:
          newGoal = 'what was AAPL stock yesterday';
          break;
        case 3:
          setIsExample3(true);
          setRatingEnabled(false);
          console.log(ratingEnabled);

          newGoal = 'using this csv of General Electric financial data, calculate the average return in 2007';
          console.log(isExample3);

          try {
            const response = await axios.get('https://agent-arena-wuwl.onrender.com/api/db/mydata', { responseType: 'blob' });
            const file = new File([response.data], 'mydata.csv', { type: 'text/csv' });
            setFile(file);
            // setLeftFile(file);
            // setRightFile(file);
            
          } catch (error) {
            toast.error('Error fetching mydata.csv');
          }
          break;
          case 4: // New Research Example case
          newGoal = 'Analyze the use of attention mechanisms in transformers for natural language processing, particularly in long-document summarization tasks';
          break;
          case 5: 
          newGoal = 'Automate the process of identifying key patterns in large datasets for predictive analysis.';
          break;
          default:
            break;
      }
    }

    setGoal(newGoal);
    await handleSearch(newGoal); 
    await loadExampleAgents(newGoal);
  };

  const loadExampleAgents = async (goal) => {
    let leftAgentName = '';
    let rightAgentName = '';

    switch (goal) {
      case 'What is new in California today':
        leftAgentName = 'langchain google-serper search agent (gpt-4o-2024-08-06)';
        rightAgentName = 'langchain brave-search agent (gpt-4o-2024-08-06)';
        break;
      case 'what was AAPL stock yesterday':
        leftAgentName = 'langchain alpha-vantage stock agent (gpt-4-turbo-2024-04-09)';
        rightAgentName = 'langchain alpha-vantage stock agent (gemini-1.5-flash-001)';
        break;
      case 'using this csv of General Electric financial data, calculate the average return in 2007':
        leftAgentName = 'langchain Pandas DataFrame (gpt-4o-2024-08-06)';
        rightAgentName = 'langchain Pandas DataFrame (claude-3-5-sonnet-20240620)';
        setIsExample3(true);
        break;
      case 'Analyze the use of attention mechanisms in transformers for natural language processing, particularly in long-document summarization tasks': // New Research Example
        leftAgentName = 'langchain ArXiv Article Fetcher (gpt-4-0613)';
        rightAgentName = 'llamaindex ArXiv Article Fetcher (claude-3-5-sonnet-20240620)';
        break;
      case 'Automate the process of identifying key patterns in large datasets for predictive analysis.': 
        leftAgentName = 'langchain Riza Code Interpreter (gpt-4o-2024-05-13)';
        rightAgentName = 'langchain Riza Code Interpreter (open-mixtral-8x22b)';
        break;
      default:
        break;
    }

    const leftAgent = agents.find(agent => agent.name === leftAgentName);
    const rightAgent = agents.find(agent => agent.name === rightAgentName);

    if (leftAgent && rightAgent) {
      setLeftExecutedCode(leftAgent.code.replace('Enter Goal/Prompt Here', goal));
      setRightExecutedCode(rightAgent.code.replace('Enter Goal/Prompt Here', goal));
      setLeftAgent(leftAgent);
      setRightAgent(rightAgent);
      setLeftCodeCollapsed(false);
      setRightCodeCollapsed(false);
    }
  };

  return (
    <Container className="d-flex flex-column align-items-center">
      <Analytics />
      <div className="w-100 mb-2 d-flex flex-column justify-content-center align-items-center">
        <h1
          className="text-center mb-1"
          style={{
            color: '#ffffff',  // Bright white for the main title
            flexGrow: 1,
            fontSize: '2.5rem',  // Larger font size for emphasis
            fontWeight: 'bold',  // Bold for stronger presence
            letterSpacing: '1px' // Added letter spacing for a sleek look
          }}>
          Agent Arena
        </h1>
        <h2
          className="text-center mt-1"
          style={{
            color: 'rgba(255, 255, 255, 0.7)', // White but with reduced opacity for a less bright effect
            fontSize: '1.25rem',  // Smaller font size than the main title
            fontWeight: '300', // Lighter weight for a softer appearance
            letterSpacing: '0.5px', // Slight letter spacing for consistency
            marginBottom: '15px' // Adequate space below the text
          }}>
        </h2>
      </div>
  
      <p className="text-center mb-4">
        Welcome to the LLM Agent Arena. Here, you can pit two agents against each other based on a goal you provide.
        You can also head to your profile to save prompts for agents and visit the Prompt Hub to see prompts used by
        other users along with their ratings. Ensure your API keys are configured in your profile for optimal performance.
      </p>
  
      {/* Search bar with file upload */}
      <Row className="mb-3 w-100 d-flex justify-content-center align-items-center">
  <Col
    xs={12}
    md={8}
    className="d-flex flex-column flex-md-row justify-content-center align-items-center"
  >
    {/* Input for goal */}
    <Form.Control
      type="text"
      placeholder={displayedPrompt || "Enter your goal"}
      value={goal}
      onChange={(e) => setGoal(e.target.value)}
      onKeyPress={(e) => {
        if (e.key === "Enter") handleSearch(goal);
      }}
      className="flex-grow-1 me-md-2 mb-2 mb-md-0"
      style={{
        borderRadius: "6px",
        padding: "10px",
        border: "1px solid #ccc",
      }}
    />

    {/* File upload button */}
    <OverlayTrigger placement="top" overlay={<Tooltip>Attach a file</Tooltip>}>
      <Button
        variant="outline-primary"
        onClick={() => document.getElementById("file-input").click()}
        className="me-md-2 mb-2 mb-md-0"
        style={{
          padding: "10px", 
          borderRadius: "6px", 
          height: "calc(100% - 2px)",  // Adjust height to match the input
          display: "flex", 
          alignItems: "center",  // Center the icon vertically
        }}
      >
        <FiPaperclip style={{ marginRight: "5px", fontSize: "18px" }} />
        Upload
      </Button>
    </OverlayTrigger>


    {/* Hidden file input */}
    <Form.Control
      id="file-input"
      type="file"
      onChange={(e) => setFile(e.target.files[0])}
      style={{ display: "none" }}
    />

    {/* Search Button */}
    <Button
      variant="primary"
      onClick={() => handleSearch(goal)}  // Pass the current goal
      style={{
        padding: "10px 20px",
        borderRadius: "6px",
        backgroundColor: "#6f42c1",
        borderColor: "#6f42c1",
      }}
    >
      Search
    </Button>
  </Col>
</Row>


{/* Display uploaded file */}
{/* File upload section */}
{file && (
  <Row className="mb-3 w-100 d-flex justify-content-center align-items-center">
    <Col className="d-flex align-items-center justify-content-center">
      <div
        className="file-upload-display d-flex align-items-center"
        style={{
          backgroundColor: '#333', // Background color for better visibility
          padding: '10px',
          borderRadius: '6px',
          maxWidth: '350px', // Limit file name width to avoid overflow
          wordWrap: 'break-word',
        }}
      >
        {/* Display file name */}
        <span
          className="file-name text-white"
          style={{ marginRight: '10px', color: '#fff', fontSize: '14px' }}
        >
          {file.name}
        </span>

        {/* Close icon to remove file */}
        <MdClose
          style={{
            cursor: 'pointer',
            color: '#ff6b6b', // Light red color for visibility
            fontSize: '20px',
          }}
          onClick={removeFile}
        />
      </div>
    </Col>
  </Row>
)}



  
      <Row className="mb-4 w-100">
        <Col className="d-none d-md-flex justify-content-center align-items-center">
          <Button
            variant="info"
            onClick={() => setExample(1)}
            className="mx-2"
            style={{
              backgroundColor: buttonStyles.search.normal,
              borderColor: buttonStyles.search.normal,
              color: theme === 'light' ? '#000' : '#000',  // Black text in light mode, white in dark mode

            }}
            onMouseEnter={(e) => e.target.style.backgroundColor = buttonStyles.search.hover}
            onMouseLeave={(e) => e.target.style.backgroundColor = buttonStyles.search.normal}
          >
            Search Example
          </Button>
          <Button
            variant="info"
            onClick={() => setExample(2)}
            className="mx-2"
            style={{
              backgroundColor: buttonStyles.finance.normal,
              borderColor: buttonStyles.finance.normal,
              color: theme === 'light' ? '#000' : '#000',  // Black text in light mode, white in dark mode

            }}
            onMouseEnter={(e) => e.target.style.backgroundColor = buttonStyles.finance.hover}
            onMouseLeave={(e) => e.target.style.backgroundColor = buttonStyles.finance.normal}
          >
            Stock Example
          </Button>
          <Button
            variant="info"
            onClick={() => setExample(3)}
            className="mx-2"
            style={{
              backgroundColor: buttonStyles.data.normal,
              borderColor: buttonStyles.data.normal,
              color: theme === 'light' ? '#000' : '#000',  // Black text in light mode, white in dark mode

            }}
            onMouseEnter={(e) => e.target.style.backgroundColor = buttonStyles.data.hover}
            onMouseLeave={(e) => e.target.style.backgroundColor = buttonStyles.data.normal}
          >
            Financial Data Example
          </Button>
          <Button
            variant="info"
            onClick={() => setExample(4)} // New Research Example
            className="mx-2"
            style={{
              backgroundColor: buttonStyles.research.normal,
              borderColor: buttonStyles.research.normal,
              color: theme === 'light' ? '#000' : '#000',
            }}
            onMouseEnter={(e) => e.target.style.backgroundColor = buttonStyles.research.hover}
            onMouseLeave={(e) => e.target.style.backgroundColor = buttonStyles.research.normal}
          >
            Research Example
          </Button>
          <Button
            variant="info"
            onClick={() => setExample(5)}
            className="mx-2"
            style={{
              backgroundColor: buttonStyles.automation.normal,
              borderColor: buttonStyles.automation.normal,
              color: theme === 'light' ? '#000' : '#000', // Black text for visibility
            }}
            onMouseEnter={(e) => e.target.style.backgroundColor = buttonStyles.automation.hover}
            onMouseLeave={(e) => e.target.style.backgroundColor = buttonStyles.automation.normal}
          >
            Automation Example
          </Button>
          <Button variant="info" onClick={() => setExample('surprise')} className="mx-2"             style={{
              color: theme === 'light' ? '#000' : '#000',  
              backgroundColor: "#17a2b8",
              borderColor: "#17a2b8"
            }}> 
            Surprise Me
          </Button>
        </Col>
        <Col xs={12} className="d-md-none">
          <Row>
          <Col xs={6} className="mb-2">
  <Button
    variant="info"
    onClick={() => setExample(1)}
    className="w-100"
    style={{
      backgroundColor: buttonStyles.search.normal,
      borderColor: buttonStyles.search.normal,
      color: theme === 'light' ? '#000' : '#000',  // Black text for visibility
    }}
    onMouseEnter={(e) => e.target.style.backgroundColor = buttonStyles.search.hover}
    onMouseLeave={(e) => e.target.style.backgroundColor = buttonStyles.search.normal}
  >
    Search Example
  </Button>
</Col>
<Col xs={6} className="mb-2">
  <Button
    variant="info"
    onClick={() => setExample(2)}
    className="w-100"
    style={{
      backgroundColor: buttonStyles.finance.normal,
      borderColor: buttonStyles.finance.normal,
      color: theme === 'light' ? '#000' : '#000',
    }}
    onMouseEnter={(e) => e.target.style.backgroundColor = buttonStyles.finance.hover}
    onMouseLeave={(e) => e.target.style.backgroundColor = buttonStyles.finance.normal}
  >
    Stock Example
  </Button>
</Col>
<Col xs={6} className="mb-2">
  <Button
    variant="info"
    onClick={() => setExample(3)}
    className="w-100"
    style={{
      backgroundColor: buttonStyles.data.normal,
      borderColor: buttonStyles.data.normal,
      color: theme === 'light' ? '#000' : '#000',
    }}
    onMouseEnter={(e) => e.target.style.backgroundColor = buttonStyles.data.hover}
    onMouseLeave={(e) => e.target.style.backgroundColor = buttonStyles.data.normal}
  >
    Financial Data Example
  </Button>
</Col>
<Col xs={6} className="mb-2">
  <Button
    variant="info"
    onClick={() => setExample(4)}
    className="w-100"
    style={{
      backgroundColor: buttonStyles.research.normal,
      borderColor: buttonStyles.research.normal,
      color: theme === 'light' ? '#000' : '#000',
    }}
    onMouseEnter={(e) => e.target.style.backgroundColor = buttonStyles.data.hover}
    onMouseLeave={(e) => e.target.style.backgroundColor = buttonStyles.data.normal}
  >
    Research Example
  </Button>
</Col>

<Col xs={6} className="mb-2">
  <Button
    variant="info"
    onClick={() => setExample(5)}
    className="w-100"
    style={{
      backgroundColor: buttonStyles.automation.normal,
      borderColor: buttonStyles.automation.normal,
      color: theme === 'light' ? '#000' : '#000', // Black text for visibility
    }}
    onMouseEnter={(e) => e.target.style.backgroundColor = buttonStyles.automation.hover}
    onMouseLeave={(e) => e.target.style.backgroundColor = buttonStyles.automation.normal}
  >
    Automation Example
  </Button>
</Col>


<Col xs={6} className="mb-2">
  <Button
    variant="info"
    onClick={() => setExample('surprise')}
    className="w-100"
    style={{
      backgroundColor: "#17a2b8",
      borderColor: "#17a2b8",
      color: theme === 'light' ? '#000' : '#000',
    }}
  >
    Surprise Me
  </Button>
</Col>
          </Row>
        </Col>
      </Row>
  
      <Row className="mb-4 w-100">
        <Col className="d-flex justify-content-center align-items-center">
          <Button
            variant="primary"
            onClick={handleRunBoth}
            className="mx-3 my-2"
            disabled={((!leftAgent || !rightAgent) || (isRunningBoth && (!leftCompleted && !rightCompleted)))}
          >
            {(isRunningBoth && (!leftCompleted || !rightCompleted)) ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="me-2"
                />
                Running...
              </>
            ) : (
              'Run Both Agents'
            )}
          </Button>
        </Col>
      </Row>
      <Row className="justify-content-center w-100">
        <Col md={5}>
          <h2 className="text-center">Agent 1</h2>
          <AgentDropdown agents={agents} selectedAgent={leftAgent} onSelect={handleLeftSelect} />
          {leftAgent ? (
            <CodeEditor
              agentId={leftAgent._id}
              initialCode={leftExecutedCode}
              onCodeChange={(updatedCode) => setLeftExecutedCode(updatedCode)}

              onExecute={(code, output) => {
                setLeftExecutedCode(code);
                setLeftOutput(output);
                setLeftCompleted(true);
              }}
              output={leftOutput}
              allowsFileUpload={leftAgent.allowsFileUpload}
              fileUploadMessage={leftAgent.fileUploadMessage}
              dbFilePath={leftAgent.allowsFileUpload ? leftFile : null}
              file={leftAgent.allowsFileUpload ? file : null}
              isExample3={isExample3}
              modificationNeeded={leftAgent.modificationNeeded}
              agentName={leftAgent.name}
              averageExecutionTime={leftAgent.averageExecutionTime}
              userApiKeys={userApiKeys}
              codeCollapsed={leftCodeCollapsed}
              setCodeCollapsed={setLeftCodeCollapsed}
              isRunningBoth={isRunningBoth}
              runBothTriggered={runBothTriggered}
              completed={leftCompleted}
            />
          ) : (
            <CodeEditor
              agentId={null}
              initialCode={leftExecutedCode}
              onExecute={(code, output) => {
                setLeftExecutedCode(code);
                setLeftOutput(output);
                setLeftCompleted(true);
              }}
              output={leftOutput}
              allowsFileUpload={false}
              codeCollapsed={leftCodeCollapsed}
              setCodeCollapsed={setLeftCodeCollapsed}
              fileUploadMessage={''}
              dbFilePath={null}
              isExample3={isExample3}
              modificationNeeded={false}
              userApiKeys={userApiKeys}
            />
          )}
        </Col>
        <Col md={5}>
          <h2 className="text-center">Agent 2</h2>
          <AgentDropdown agents={agents} selectedAgent={rightAgent} onSelect={handleRightSelect} />
          {rightAgent ? (
            <CodeEditor
              agentId={rightAgent._id}
              initialCode={rightExecutedCode}
              onExecute={(code, output) => {
                setRightExecutedCode(code);
                setRightOutput(output);
                setRightCompleted(true);
              }}
              output={rightOutput}
              allowsFileUpload={rightAgent.allowsFileUpload}
              fileUploadMessage={rightAgent.fileUploadMessage}
              dbFilePath={rightAgent.allowsFileUpload ? rightFile : null}
              file={rightAgent.allowsFileUpload ? file : null}
              onCodeChange={(updatedCode) => setRightExecutedCode(updatedCode)}
              isExample3={isExample3}
              modificationNeeded={rightAgent.modificationNeeded}
              agentName={rightAgent.name}
              averageExecutionTime={rightAgent.averageExecutionTime}
              userApiKeys={userApiKeys}
              codeCollapsed={rightCodeCollapsed}
              setCodeCollapsed={setRightCodeCollapsed}
              isRunningBoth={isRunningBoth}
              runBothTriggered={runBothTriggered}
              completed={rightCompleted}
            />
          ) : (
            <CodeEditor
              agentId={null}
              initialCode={rightExecutedCode}
              onExecute={(code, output) => {
                setRightExecutedCode(code);
                setRightOutput(output);
                setRightCompleted(true);
              }}
              output={rightOutput}
              allowsFileUpload={false}
              fileUploadMessage={''}
              codeCollapsed={rightCodeCollapsed}
              setCodeCollapsed={setRightCodeCollapsed}
              dbFilePath={null}
              isExample3={isExample3}
              modificationNeeded={false}
              userApiKeys={userApiKeys}
              completed={leftCompleted}
            />
          )}
        </Col>
      </Row>
      <Row className="mt-4 w-100">
        <Col className="text-center">
          <Button
            variant="success"
            onClick={() => handleRating('A is better')}
            className="mx-3 my-2"
            disabled={!ratingEnabled || hasVoted}
          >
            A is better
          </Button>
          <Button
            variant="success"
            onClick={() => handleRating('B is better')}
            className="mx-3 my-2"
            disabled={!ratingEnabled || hasVoted}
          >
            B is better
          </Button>
          <Button
            variant="info"
            onClick={() => handleRating('Tie')}
            className="mx-3 my-2"
            disabled={!ratingEnabled || hasVoted}
          >
            Tie
          </Button>
          <Button
            variant="danger"
            onClick={() => handleRating('Both are bad')}
            className="mx-3 my-2"
            disabled={!ratingEnabled || hasVoted}
          >
            Both are bad
          </Button>
        </Col>
      </Row>
      {!isLoggedIn && (
        <Row className="mt-4 justify-content-center w-100">
          <Col className="text-center">
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>You can share the result after voting!</Tooltip>}
            >
              <span className="d-inline-block">
                <Button
                  variant="primary"
                  onClick={handleShareSession}
                  className="mt-2"
                  disabled={!hasVoted}
                  style={{ pointerEvents: !hasVoted ? 'none' : 'auto' }}
                >
                  Share Result
                </Button>
              </span>
            </OverlayTrigger>
          </Col>
        </Row>
      )}
      {isLoggedIn && (
        <Row className="mt-4 justify-content-center w-100">
          <Col className="text-center">
            <Button variant="primary" onClick={handleSavePrompt} className="mt-2" disabled={!hasVoted}>
              Save Prompt
            </Button>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>You can share the result after voting!</Tooltip>}
            >
              <span className="d-inline-block">
                <Button
                  variant="secondary"
                  onClick={() => {
                    navigator.clipboard.writeText(shareURL);
                    toast.success('Result link copied to clipboard');
                  }}
                  className="mt-2"
                  disabled={!shareURL}
                  style={{ pointerEvents: !shareURL ? 'none' : 'auto' }}
                >
                  Share Result
                </Button>
              </span>
            </OverlayTrigger>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default AgentArena;